.joined-overlay {
    align-items: center;
    background-color: var(--variable-collection-primary-1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 16px;
    width: 100%;
    max-width: 560px;
}

.joined-overlay .top-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
}

.joined-overlay .title {
    color:  var(--variable-collection-primary-6);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
}

.joined-overlay .accent {
    color:  var(--variable-collection-primary-3);;
}

.joined-overlay .info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.joined-overlay .text-wrapper {
    align-self: stretch;
    color:  var(--variable-collection-primary-6);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
}