.dots-button {
    all: unset;
    cursor: pointer;
}

.dots-button > svg > path {
    fill: var(--variable-collection-primary-4);
}

.dots-button:active > svg > path {
    opacity: 0.5;
}