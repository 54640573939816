.close-button {
    all: unset;
    cursor: pointer;
}

.close-button > svg > path {
    fill: var(--variable-collection-primary-6);
}

.close-button:hover > svg > path {
    fill: var(--variable-collection-primary-4);
}

.close-button:active > svg > path {
    opacity: 0.5;
}