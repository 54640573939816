.text-input-box {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
}

.text-input-box .text-input-label-wrapper {
    color: var(--variable-collection-primary-6);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.text-input-box .input {
    align-self: stretch;
    background: none;
    border: 1px solid;
    border-color: var(--variable-collection-primary-4);
    color: var(--variable-collection-primary-6);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    height: 48px;
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
    padding: 0px 8px;
    white-space: nowrap;
    width: 100%;
}

.text-input-box .input:hover {
    border-color: var(--variable-collection-primary-3);
}

.text-input-box .input:invalid {
    border-color: var(--variable-collection-secondry-0);
}

.text-input-box .input.invalid {
    border-color: var(--variable-collection-secondry-0);
}

.text-input-box .input:focus {
    outline: none;
}

@media only screen and (max-width: 400px) {
    .text-input-box .input {
        font-family: var(--s6-primary-font-family);
        font-size: var(--s6-primary-font-size);
        font-style: var(--s6-primary-font-style);
        font-weight: var(--s6-primary-font-weight);
        letter-spacing: var(--s6-primary-letter-spacing);
        line-height: var(--s6-primary-line-height);
    }
}