.info-card {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-card .title {
  color: var(--variable-collection-primary-6);
  font-family: var(--s5-primary-font-family);
  font-size: var(--s5-primary-font-size);
  font-style: var(--s5-primary-font-style);
  font-weight: var(--s5-primary-font-weight);
  letter-spacing: var(--s5-primary-letter-spacing);
  line-height: var(--s5-primary-line-height);
  width: fit-content;
}

.info-card .content {
  color: var(--variable-collection-primary-4);
  font-family: var(--s3-primary-font-family);
  font-size: var(--s3-primary-font-size);
  font-style: var(--s3-primary-font-style);
  font-weight: var(--s3-primary-font-weight);
  letter-spacing: var(--s3-primary-letter-spacing);
  line-height: var(--s3-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

@media only screen and (max-width: 400px) {
  .info-card .content {
    font-family: var(--s4-primary-font-family);
    font-size: var(--s4-primary-font-size);
    font-style: var(--s4-primary-font-style);
    font-weight: var(--s4-primary-font-weight);
    letter-spacing: var(--s4-primary-letter-spacing);
    line-height: var(--s4-primary-line-height);
  }
}