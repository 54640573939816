@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

:root {
    --s1-primary-font-family: "Poppins", Helvetica;
    --s1-primary-font-size: 90px;
    --s1-primary-font-style: normal;
    --s1-primary-font-weight: 400;
    --s1-primary-letter-spacing: 0px;
    --s1-primary-line-height: 115%;

    --s2-primary-font-family: "Poppins", Helvetica;
    --s2-primary-font-size: 64px;
    --s2-primary-font-style: normal;
    --s2-primary-font-weight: 400;
    --s2-primary-letter-spacing: 0px;
    --s2-primary-line-height: 100%;

    --s3-primary-font-family: "Poppins", Helvetica;
    --s3-primary-font-size: 45px;
    --s3-primary-font-style: normal;
    --s3-primary-font-weight: 400;
    --s3-primary-letter-spacing: 0px;
    --s3-primary-line-height: 100%;

    --s4-primary-font-family: "Poppins", Helvetica;
    --s4-primary-font-size: 32px;
    --s4-primary-font-style: normal;
    --s4-primary-font-weight: 400;
    --s4-primary-letter-spacing: 0px;
    --s4-primary-line-height: 100%;

    --s5-primary-font-family: "Poppins", Helvetica;
    --s5-primary-font-size: 23px;
    --s5-primary-font-style: normal;
    --s5-primary-font-weight: 400;
    --s5-primary-letter-spacing: 0px;
    --s5-primary-line-height: 125%;

    --s6-primary-font-family: "Poppins", Helvetica;
    --s6-primary-font-size: 16px;
    --s6-primary-font-style: normal;
    --s6-primary-font-weight: 400;
    --s6-primary-letter-spacing: 0.24px;
    --s6-primary-line-height: 125%;

    --s7-primary-font-family: "Poppins", Helvetica;
    --s7-primary-font-size: 12px;
    --s7-primary-font-style: normal;
    --s7-primary-font-weight: 400;
    --s7-primary-letter-spacing: 0.18px;
    --s7-primary-line-height: 125%;

    --variable-collection-primary-0: rgba(0, 8, 20, 1);
    --variable-collection-primary-1: rgba(0, 29, 61, 1);
    --variable-collection-primary-2: rgba(0, 53, 102, 1);
    --variable-collection-primary-3: rgb(255, 195, 0);
    --variable-collection-primary-4: rgba(255, 214, 10, 1);
    --variable-collection-primary-4-10: rgba(255, 214, 10, 0.1);
    --variable-collection-primary-5: rgba(64, 64, 64, 1);
    --variable-collection-primary-6: rgba(255, 255, 255, 1);
    --variable-collection-secondry-0: rgba(151, 71, 255, 1);
    --variable-collection-secondry-1: rgba(29, 185, 84, 1);

    user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.popup-content {
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popup-overlay {
    padding: 0px 24px;
    background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}