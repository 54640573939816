.text-button {
    all: unset;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.text-button .button-text-wrapper {
    color: var(--variable-collection-primary-3);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
    text-wrap: nowrap;
    text-align: center;
}

.text-button:hover .button-text-wrapper {
    text-decoration: underline;
}

.text-button:disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.text-button:active {
    opacity: 50%;
}