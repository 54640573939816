.track-card {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    gap: 8px;
    width: 100%;
}

.track-card .left-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.track-card .track-cover {
    height: 48px;
    width: 48px;
    object-fit: cover;
}

.track-card .track-data {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
}

.track-card .title {
    color: var(--variable-collection-primary-6);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
    width: 100%;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    display: -webkit-box;
}

.track-card .artist {
    color: var(--variable-collection-primary-6);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
    width: 100%;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    display: -webkit-box;
}

.track-card:hover {
    outline: 1px solid;
    outline-color: var(--variable-collection-primary-4);
}

.track-card .button-wrapper {
    display: flex;
    align-items: center;
}