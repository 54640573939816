.loader-box {
    align-items: center;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    align-self: stretch;
}

svg.loader-icon {
    fill: var(--variable-collection-primary-3);
    color: var(--variable-collection-primary-3);
}