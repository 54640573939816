.top-playlists {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 356px;
  height: 501px;
  max-height: 501px;
}

.top-playlists .top-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  width: 100%;
}

.top-playlists .text-wrapper-2 {
  color: var(--variable-collection-primary-6);
  font-family: var(--s4-primary-font-family);
  font-size: var(--s4-primary-font-size);
  font-style: var(--s4-primary-font-style);
  font-weight: var(--s4-primary-font-weight);
  letter-spacing: var(--s4-primary-letter-spacing);
  line-height: var(--s4-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.top-playlists .playlsits {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 4px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.top-playlists .end-card {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 16px;
}

.top-playlists ::-webkit-scrollbar {
  width: 16px;
}

.top-playlists ::-webkit-scrollbar-track {
  background: transparent;
}

.top-playlists ::-webkit-scrollbar-thumb {
  background: transparent;
  box-shadow: inset 0 0 16px var( --variable-collection-primary-1);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.top-playlists ::-webkit-scrollbar-thumb:hover {
  background: transparent;
  box-shadow: inset 0 0 16px var(--variable-collection-primary-2);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}