.playlist-tracked {
    align-items: center;
    background-color: var(--variable-collection-primary-1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 16px;
    max-width: 560px;
    width: 100%;
}

.playlist-tracked .top-section {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
}

.playlist-tracked .title {
    color: var(--variable-collection-primary-6);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
    width: fit-content;
}

.playlist-tracked .info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.playlist-tracked .info-text-wrapper {
    align-self: stretch;
    color: var(--variable-collection-primary-6);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
}

.playlist-tracked .text-accent {
    color: var(--variable-collection-primary-3);
}

.playlist-tracked .p {
    align-self: stretch;
    color: var(--variable-collection-primary-6);
    font-family: var(--s7-primary-font-family);
    font-size: var(--s7-primary-font-size);
    font-style: var(--s7-primary-font-style);
    font-weight: var(--s7-primary-font-weight);
    letter-spacing: var(--s7-primary-letter-spacing);
    line-height: var(--s7-primary-line-height);
}

@media only screen and (max-width: 400px) {
    .playlist-tracked .title {
        font-family: var(--s6-primary-font-family);
        font-size: var(--s6-primary-font-size);
        font-style: var(--s6-primary-font-style);
        font-weight: var(--s6-primary-font-weight);
        letter-spacing: var(--s6-primary-letter-spacing);
        line-height: var(--s6-primary-line-height);
    }
}