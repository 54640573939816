.popup-menu {
    align-items: flex-start;
    background-color: var(--variable-collection-primary-1);
    border: 2px solid;
    border-color: var(--variable-collection-primary-2);
    display: flex;
    flex-direction: column;
    margin: 4px 4px;
}

.popup-menu .item {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    width: 100%;
    cursor: pointer;
}

.popup-menu .item-text:hover {
    color: var(--variable-collection-primary-3);
}

.popup-menu .item-text {
    color: var(--variable-collection-primary-6);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
}