.radio-card {
    align-items: center;
    border: 1px solid;
    display: inline-flex;
    justify-content: center;
    padding: 8px 16px;
    border-color: var(--variable-collection-primary-2);
    cursor: pointer;
}

.radio-card .text-wrapper {
    color: var(--variable-collection-primary-6);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.radio-card.active {
    border-color: var(--variable-collection-primary-4);
}

@media only screen and (max-width: 400px) {
    .radio-card .text-wrapper {
        font-family: var(--s6-primary-font-family);
        font-size: var(--s6-primary-font-size);
        font-style: var(--s6-primary-font-style);
        font-weight: var(--s6-primary-font-weight);
        letter-spacing: var(--s6-primary-letter-spacing);
        line-height: var(--s6-primary-line-height);
    }
}