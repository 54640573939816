.playlist-card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-end;
    padding: 8px;
    width: 100%;
    cursor: pointer;
}

.playlist-card .playlist-info {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    gap: 16px;
    width: 100%;
}

.playlist-card .cover {
    object-fit: cover;
    height: 48px;
    width: 48px;
}

.playlist-card .data {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    justify-content: flex-end;
}

.playlist-card .text-wrapper {
    color: var(--variable-collection-primary-6);
    font-family: var(--s5-primary-font-family);
    font-size: var(--s5-primary-font-size);
    font-style: var(--s5-primary-font-style);
    font-weight: var(--s5-primary-font-weight);
    letter-spacing: var(--s5-primary-letter-spacing);
    line-height: var(--s5-primary-line-height);
    width: 100%;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    display: -webkit-box;
}

.playlist-card .stats {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.playlist-card .left-section {
    align-items: center;
    display: inline-flex;
    gap: 16px;
}

.playlist-card .div {
    color: var(--variable-collection-primary-4);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.playlist-card .div.sponsored {
    color: var(--variable-collection-secondry-0);
}

.playlist-card:hover {
    outline: 1px solid;
    outline-color: var(--variable-collection-primary-4);
}

@media only screen and (max-width: 430px) {
    .playlist-card .left-section {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        gap: 0px;
    }

    .playlist-card .stats {
        align-items: flex-end;
    }

    .playlist-card .cover {
        height: 72px;
        width: 72px;
    }
}