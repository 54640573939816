.switch {
    align-items: center;
    outline: 1px solid;
    outline-color: var(--variable-collection-primary-3);
    display: flex;
    justify-content: center;
    width: 100%;
}

.switch .option {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 8px 16px;
}

.switch .option.active {
    background-color: var(--variable-collection-primary-3);
}

.switch .text-wrapper {
    color: var(--variable-collection-primary-6);
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
    white-space: nowrap;
    width: fit-content;
}

.switch .option.option.active .text-wrapper {
    color: var(--variable-collection-primary-1);   
    font-weight: 500;
}