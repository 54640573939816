.box-button {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: var(--variable-collection-primary-4);
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 8px 16px;
  cursor: pointer;
}

.box-button .button-text-wrapper {
  color: var(--variable-collection-primary-6);
  font-family: var(--s5-primary-font-family);
  font-size: var(--s5-primary-font-size);
  font-style: var(--s5-primary-font-style);
  font-weight: var(--s5-primary-font-weight);
  letter-spacing: var(--s5-primary-letter-spacing);
  line-height: var(--s5-primary-line-height);
  white-space: nowrap;
  width: fit-content;
}

.box-button:hover {
  background-color: var(--variable-collection-primary-4-10);
}

.box-button:active {
  opacity: 0.7;
}

@media only screen and (max-width: 400px) {
  .box-button .button-text-wrapper {
    font-family: var(--s6-primary-font-family);
    font-size: var(--s6-primary-font-size);
    font-style: var(--s6-primary-font-style);
    font-weight: var(--s6-primary-font-weight);
    letter-spacing: var(--s6-primary-letter-spacing);
    line-height: var(--s6-primary-line-height);
  }
}